import React, { Fragment } from 'react';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleCurrencyInput from '../form/simple_currency';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import ReservationCounter from './ReservationCounter';
import ReservationOfferDialogConfirmation from './ReservationOfferDialogConfirmation';
import axios from 'axios';

const styles = {
  Checkbox: {
    color: '#00ccff',
  },
  TableCell: {
    color: '#006795',
  },
};

const SimpleCurrencyInputCustom = (props) => {
  const { onChange, name, style, value, ...other } = props;
  const className = `input-masked-money ${other.className}`;
  return (
    <SimpleCurrencyInput
      {...other}
      onInputFocus={(el) => {
        const { target } = el;
        const pos = target.value.length;
        target.setSelectionRange(pos, pos);
      }}
      style={{ ...style, textAlign: 'right' }}
      precision={3}
      className={className}
      type="tel"
      value={value}
      separator=","
      delimiter="."
      onInputChange={onChange}
    />
  );
};

const { CREDIT, DEBIT } = { DEBIT: 'debit', CREDIT: 'credit' };

export default class ReservationOfferSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offersSelected: this.getOfferSelected(),
      offersLitersUnits: this.getOffersLitersUnits(),
      paymentMethod: '',
      totalUnits: 0,
      dialogConfirmationOpen: false,
    };
  }

  getOfferSelected() {
    return this.props.bookedPayments.map((payment) => {
      const offer = payment.offer;
      offer.product = payment.product;
      return offer;
    });
  }

  getOffersLitersUnits() {
    return !this.props.preFillReserves
      ? {}
      : this.props.bookedPayments.reduce((acc, payment) => {
          acc[payment.offer.id] = {
            value: payment.total_units,
            rawValue: payment.total_units * 1000,
          };
          return acc;
        }, {});
  }

  isOfferSelected = (offer) => {
    return !!this.state.offersSelected.find((offerSelected) => offerSelected.id === offer.id);
  };

  handleOfferSelection = (offer) => () => {
    if (this.isOfferSelected(offer)) {
      const offersSelected = this.state.offersSelected.filter((o) => o.id !== offer.id);
      this.handleLitersUnitsUpdate(offer)(0);
      this.setState({ offersSelected });
    } else {
      this.setState({ offersSelected: this.state.offersSelected.concat(offer) });
    }
  };

  handleLitersUnitsUpdate = (offer) => (value) => {
    const { offersLitersUnits } = this.state;

    var maxQuantity = offer.product_limit * 1000;

    if (value <= maxQuantity) {
      offersLitersUnits[offer.id] = {
        rawValue: value,
        value: parseFloat(value / 1000),
      };
    }

    const totalUnits = Object.keys(offersLitersUnits).reduce((acc, offerId) => {
      const liters = parseFloat(offersLitersUnits[offerId].value);
      return acc + (liters || 0);
    }, 0);

    this.setState({ offersLitersUnits, totalUnits });
  };

  handleProceedToPayment = (paymentMethod) => {
    const offers = this.state.offersSelected
      .map((offer) => {
        offer.liters_units = this.state.offersLitersUnits[offer.id].value;
        return offer;
      })
      .filter((offer) => offer.liters_units > 0);
    this.setState({ offersWithUnits: offers, paymentMethod, dialogConfirmationOpen: true });
  };

  onClose = () => {
    this.setState({ dialogConfirmationOpen: false });
  };

  getLitersForOffer = (offer) => {
    const offersUser = this.state.offersLitersUnits[offer.id];
    return !!offersUser ? offersUser.rawValue : 0;
  };

  canPayWithDebit = () => {
    return this.state.totalUnits > 0 && this.state.offersSelected.every((offer) => offer.price > 0);
  };

  canPayWithCredit = () => {
    return this.state.totalUnits > 0 && this.state.offersSelected.every((offer) => offer.price_credit > 0);
  };

  renderOffersTable(offers) {
    const rows = offers.map((offer) => {
      const isOfferSelected = this.isOfferSelected(offer);

      let moneyTag;
      let literTag;

      if (offer.is_fuel) {
        literTag = <span style={{ margin: '0 5px', fontSize: '0.7em' }}>L</span>;
      } else {
        moneyTag = <span style={{ margin: '0px 5px 0px 0px', fontSize: '0.7em' }}>R$</span>;
      }

      return (
        <TableRow
          id={`payment-${offer.id}`}
          key={offer.id}
          hover={false}
          role="checkbox"
          aria-checked={isOfferSelected}
          selected={isOfferSelected}
          className={`${isOfferSelected ? 'background-blue' : ''}`}
        >
          <TableCell onClick={this.handleOfferSelection(offer)} width="70">
            <Checkbox checked={isOfferSelected} color="primary" style={styles.Checkbox} />
          </TableCell>

          <TableCell style={styles.TableCell} className="reservation__product-name" onClick={this.handleOfferSelection(offer)}>
            {offer.product.name}
          </TableCell>

          <TableCell style={styles.TableCell} className="reservation__offer-kind" onClick={this.handleOfferSelection(offer)}>
            {offer.kind}
          </TableCell>

          <TableCell
            width="155"
            style={styles.TableCell}
            className="reservation__value alignright"
            onClick={() => !isOfferSelected && this.handleOfferSelection(offer)}
          >
            {!isOfferSelected ? (
              '--'
            ) : (
              <Fragment>
                {moneyTag}
                <TextField
                  value={this.getLitersForOffer(offer)}
                  id={`liters_quantity-${offer.id}`}
                  onChange={this.handleLitersUnitsUpdate(offer)}
                  autoFocus={true}
                  style={{ width: 110 }}
                  max={999999}
                  InputProps={{
                    inputComponent: SimpleCurrencyInputCustom,
                  }}
                />
                {literTag}
              </Fragment>
            )}
          </TableCell>
        </TableRow>
      );
    });

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Produtos</TableCell>
            <TableCell>Oferta</TableCell>
            <TableCell numeric>Quantidade</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  }

  renderLoadingPayments = () => {
    return (
      <Fragment>
        <CircularProgress size={80} />
        <br />
        <span> Aguardando novas reservas ...</span>
      </Fragment>
    );
  };

  render() {
    const { offer = [] } = this.props;
    const availableOffers = _.uniqBy(this.props.offers, 'product.id').sort((a, b) => a.product.position - b.product.position);

    return (
      <div className="reserve">
        <div className="heading">ESCOLHA O COMBUSTÍVEL E OFERTA:</div>
        <div className="content-entry">
          <div className="container">
            <div className="form super">{this.renderOffersTable(availableOffers)}</div>
          </div>
        </div>
        <div className="button__container">
          <button className="button--primary payment-debit" disabled={!this.canPayWithDebit()} onClick={() => this.handleProceedToPayment(DEBIT)}>
            DINHEIRO OU DÉBITO
          </button>
          <button className="button--primary payment-credit" disabled={!this.canPayWithCredit()} onClick={() => this.handleProceedToPayment(CREDIT)}>
            CARTÃO DE CRÉDITO
          </button>
        </div>

        {this.state.offersWithUnits && (
          <ReservationOfferDialogConfirmation
            open={this.state.dialogConfirmationOpen}
            onClose={this.onClose}
            paymentMethod={this.state.paymentMethod}
            leavePath={this.props.base_path}
            confirmationPath={this.props.confirmationPath}
            basePath={this.props.basePath}
            litersToBeUsed={this.props.litersToBeUsed}
            credits={this.props.credits}
            bankBalance={this.props.bankBalance}
            offers={this.state.offersWithUnits}
            handleReservationConfirmation={this.props.handleReservationConfirmation}
            history={this.props.history}
          />
        )}

        <ReservationCounter expireInSeconds={this.props.expireInSeconds} basePath={this.props.basePath} leavePath={this.props.leavePath} />
      </div>
    );
  }
}
