import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ReservationOfferSelection from './reservation/ReservationOfferSelection';
import CheckInUser from './reservation/CheckInUser';
import UpdatePriceForm from './reservation/UpdatePriceForm';
import ReservationSummary from './reservation/ReservationSummary';
import UserRegistrationFormContainer from './UserRegistrationFormContainer';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#6bffff',
      main: '#00ccff',
      dark: '#009bcc',
      contrastText: '#fff',
    },
  },
});

export default class ReservationFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offersSelected: [],
      summary: null,
      priceUpdateTime: props.price_update_time,
      reservation: {},
    };
  }

  handlePriceUpdated = () => {
    this.setState({ priceUpdateTime: true });
  };

  handleProductSelected = ({ offers: offersSelected, paymentType }) => {
    this.setState({ offersSelected, paymentType });
  };

  handleReservationConfirmation = (summary) => {
    this.setState({ summary });
  };

  handleCheckInUser = (reservation) => {
    this.setState({ reservation: reservation });
  };

  handleEndReservation = () => {
    this.setState({ reservation: {} });
  };

  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          {this.props.price_update_time && <Redirect to={`${this.props.base_path}/update_price`} />}

          <Route
            exact
            path={`${this.props.base_path}/cadastro_usuario`}
            component={(props) => (
              <UserRegistrationFormContainer
                basePath={this.props.base_path}
                companyFastReservationCheckInPath={this.props.company_fast_reservation_check_in_path}
                reserva_base_path={'/posto/reserva_rapida'}
                handleCheckInUser={this.handleCheckInUser}
                {...props}
              />
            )}
          />

          <Route
            exact
            path={`${this.props.base_path}/update_price`}
            component={(props) => (
              <UpdatePriceForm
                basePath={this.props.base_path}
                priceUpdatePath={this.props.price_product_path}
                newPriceUpdatePath={this.props.new_price_product_path}
                {...props}
              />
            )}
          />

          <Route
            exact
            path={`${this.props.base_path}/`}
            component={(props) => (
              <CheckInUser
                company={this.props.company}
                handleCheckInUser={this.handleCheckInUser}
                skipCameraUsage={this.props.skip_camera_usage}
                basePath={this.props.base_path}
                companyFastReservationCheckInPath={this.props.company_fast_reservation_check_in_path}
                newPriceUpdatePath={this.props.new_price_product_path}
                companyFastReservationValidateCpfPath={this.props.company_fast_reservation_validate_cpf_path}
                frontUserPermitPickupConfirmation={this.props.front_user_permit_pickup_confirmation}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`${this.props.base_path}/select_products`}
            component={(props) =>
              this.state.reservation.offers ? (
                <ReservationOfferSelection
                  expireInSeconds={this.state.reservation.validator.expire_in_seconds}
                  offers={this.state.reservation.offers}
                  credits={this.state.reservation.credits}
                  litersToBeUsed={this.state.reservation.available_liters}
                  bookedPayments={this.state.reservation.booked_payments}
                  confirmationPath={this.state.reservation.confirmation_path}
                  basePath={this.props.base_path}
                  preFillReserves={this.props.front_user_fast_flow_pre_fill}
                  leavePath={this.props.base_path}
                  handleProductSelected={this.handleProductSelected}
                  handleReservationConfirmation={this.handleReservationConfirmation}
                  {...props}
                />
              ) : (
                <Redirect to={`${this.props.base_path}/`} />
              )
            }
          />
          <Route
            path={`${this.props.base_path}/summary`}
            render={(props) =>
              this.state.summary !== null ? (
                <ReservationSummary summary={this.state.summary} leavePath={this.props.base_path} handleEndReservation={this.handleEndReservation} {...props} />
              ) : (
                <Redirect to={`${this.props.base_path}/`} />
              )
            }
          />
        </MuiThemeProvider>
      </Router>
    );
  }
}
